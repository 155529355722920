<template>
  <div class="about">
    <Nav paths="/about" />
    <div class="banner">
      <div class="banner-bg"></div>
    </div>
    <div class="aboat">
      <div class="left" data-aos="fade-right" data-aos-duration="750">
        <h1><span>About</span> Technican</h1>
        <h2>关于泰克尼康</h2>
        <div class="text">
          <p>泰克尼康科技有限公司(简称泰克尼康，英文缩写TCNC)</p>
          <p>成立于2012年，是冻眠速冻技术应用整体解决方案服务商，致力于冻眠速冻技术的研究开发和推广应用。</p>
          <p>冻眠是一种颠覆传统的液体高速冻结技术，可以让食品药品长久新鲜。由于冻眠速冻技术对人类持续发展作出重大贡献，因而获得联合国的大力倡导。</p>
          <p>公司早于2017年便进入了冻眠速冻技术领域的耕耘，开创了中国冻眠速冻时代。我们致力于横向打造冻眠速冻应用场景，传播冻眠文化，塑造冻眠品牌，让食(药)品更新鲜、更环保、更健康;社会更节约，生活更美好！</p>
        </div>
      </div>
      <div class="right" data-aos="fade-left" data-aos-duration="750">
        <img :src="require('@/assets/about/bg1.png')" alt="">
      </div>
    </div>
    <div class="trait" data-aos="fade-up" data-aos-duration="1000">
      <div class="items" v-for="(item,i) in trait_list" :key="i">
        <img :src="item.image" width="65" height="65">
        <h3>{{item.title}}</h3>
        <span>{{item.text}}</span>
      </div>
    </div>
    <div class="skills" data-aos="fade-up" data-aos-duration="750">
      <div class="explain">
        <h3>冻眠速冻 = 活力保鲜</h3>
        <div class="desc">
          <p>冻眠速冻时代已来。彻底颠覆传统，减少浪费，更环保、更新鲜、更健康，生活更美好的冻眠速冻时代就在眼前！</p>
          <p>“革命者永远是年轻”，这句改动的励志歌词，注释着企业家必备的情怀！</p>
          <p>泰克尼康企业创始人刘洪远感言：对人类进步的关怀，对社会不能抗拒的责任坚定的脚步，无惧困难，是每一个企业家的人生标配！科技强国，科技兴邦，让我们共同分享冻眠科技的美好时代！</p>
        </div>
      </div>
    </div>
    <div class="process" data-aos="fade-up" data-aos-duration="1000">
      <div class="yellow">
        <div class="blue">
          <div class="white">
          </div>
        </div>
      </div>
      <div class="left">
        <div class="text1">
          <h3>冻眠食品开发</h3>
          <p>冻眠市场，是我们精心打造的一个广泛的冻眠食品标识，同时也是一个赋能平台。我们欢迎商家以合作加盟方式，与我们共同开发冻眠市场的各类食品。面向对生活品质有追求的广大客户，奉献我们精心打造的更新鲜、更环保、更健康的高端冻眠产品。</p>
        </div>
        <div class="text2">
          <h3>冻眠设备生产</h3>
          <p>2021年，冻眠设备将在国内落地生产。面对食品冷链源头，例如渔港码头、畜牧屠宰场、食品加工地等的大型冻眠设备将陆续面世。同时，进一步将中小型冻眠设备普及到家庭、幼儿园、学校及工厂机关酒店食堂。</p>
        </div>
      </div>
      <div class="right">
        <div class="text2">
          <h3>冻眠技术授权</h3>
          <p>输出我们引以为傲的冻眠技术，协同投资者共同打造品质化、标准化、品牌化的各类特色高端产品。不断满足高品质生活的市场需求。</p>
        </div>
        <div class="text1">
          <h3>冻眠文化传播</h3>
          <p>5G时代，我们将在全网全媒体打造冻眠文化产业平台。未来，我们的冻眠产品客户、冻眠技术合作者、冻眠关联产业都可在冻眠文化平台开展宣传、推广、对接客户、增值，实现互利共赢的目标。</p>
        </div>
      </div>
    </div>
    <div class="teams">
      <div class="title" data-aos="fade-up" data-aos-duration="1000">
        <div class="en-title">Company Team</div>
        <div class="chs-title">公司团队</div>
      </div>
      <div class="team-lists">
        <div class="team-item" v-for="(item,i) in team_list" :key="i" data-aos="fade-up" data-aos-duration="1000" @click="showDialog(item)">
          <div class="pic">
            <img :src="item.pic" width="150" height="150">
          </div>
          <div class="info">
            <div class="posts">{{item.posts}}</div>
            <div class="fullname">{{item.fullname}}</div>
            <div class="profiles">
              <p>{{item.profiles}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="food-base">
      <div class="left" data-aos="fade-right" data-aos-duration="1000">
        <h3>泰克尼康南沙食品基地简介</h3>
        <p>泰克尼康南沙食品基地，位于大湾区中心城市广州南沙区，主营业务为冻眠农产品加工、冻眠食品生产。冻眠技术是一项颠覆传统的先进冷冻技术。利用此项技术公司先后开发了冻眠斑节对虾熟食品(产品名为“庖小鲜”)，以及冻眠文昌鸡等多个冻眠品牌食品，形成了“冻眠市场”的连锁产品系列。</p>
      </div>
      <div class="right" data-aos="fade-left" data-aos-duration="1000">
        <div class="top">
          <h3>食品基地</h3>
          <p>我们秉承让食品更新鲜、更环保、更健康，社会更节约，生活更美好的宗旨，坚守着生产的不仅是产品更重要的是信誉和质量的经营理念，不断吸收行业新技术和国际先进设备的引进，并持续不断进行技术创新，用匠心打造冻眠科技的中国第一品牌。</p>
        </div>
        <div class="bottom">
          <div class="bg">
            <img :src="require('@/assets/about/bg4.png')" width="369" height="277">
          </div>
          <div class="bg">
            <img :src="require('@/assets/about/bg5.png')" width="369" height="277">
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="详细介绍" class="messages" :visible.sync="dialogVisible" :before-close="handleClose" width="800px">
      <div class="team-item">
        <div class="pic">
          <img :src="team_one.pic" width="150" height="150">
        </div>
        <div class="info">
          <div class="posts">{{team_one.posts}}</div>
          <div class="fullname">{{team_one.fullname}}</div>
          <div class="profiles">
            <p>{{team_one.profiles}}</p>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-backtop :visibility-height="300" :bottom="100" />
  </div>
</template>


<script>
import Nav from '@/components/nav'
export default {
  data () {
    return {
      dialogVisible: false,
      trait_list: [
        { image: require('@/assets/about/icon1.png'), title: '企业愿景', text: '让生命永远健康' },
        { image: require('@/assets/about/icon2.png'), title: '企业使命', text: '为人民的生命健康打造冻眠科技的中国品牌' },
        { image: require('@/assets/about/icon3.png'), title: '价值观', text: '以终为始，笃定前行以善良拥抱世界' },
        { image: require('@/assets/about/icon4.png'), title: '知识产权', text: '截止2022年初，我们已向国家知识产权局申报了92项商标权、47项专利权及12项软件著作权。' },
      ],
      team_list: [
        { id: '1', pic: require('@/assets/about/team1.png'), fullname: '刘洪远', posts: '董事局主席/创始人', profiles: '祖籍山东  出生于铁路工人家庭 父亲毕业于黄埔军校十七期。当过知青1978年考入西北师范大学历史系，大学毕业后先后从事师范学校教师、某市政府市长秘书。政府秘书工作期间，参与中国农村第二步改革调查报告的调研撰写及中国普法成就典型报告的撰写。1987年进入律师行业，资深律师，至今执业35年，办理过大量各类案件。2012年创办泰克尼康科技有限公司，2018年着手引进冻眠科技，开创中国冻眠技术新时代。勤奋善思，胸怀宽广，诚信待人，愿与志同道合者共同打造冻眠科技的中国品牌。致力于泰克尼康登录资本市场，让投资人分享冻眠科技的红利。无论名利、做祖国建设的自愿者、社会进步的贡献者。' },
        { id: '2', pic: require('@/assets/about/team2.png'), fullname: '于洪林', posts: '副董事长/联合创始人', profiles: '冻眠技术应用研究及冻眠科技引入中国关键推手。' },
        { id: '3', pic: require('@/assets/about/team3.png'), fullname: '刘津良', posts: '副董事长/总裁', profiles: '博鳌论坛青年“双创领袖”代表人物、广深自媒体联盟发起人。' },
        { id: '4', pic: require('@/assets/about/team4.png'), fullname: '张邓有', posts: '董事/设备部总裁', profiles: '机械专业高级工程师、数十年企业厂长，质量体系管理专家。' },
        { id: '5', pic: require('@/assets/about/team5.png'), fullname: '邱国丰', posts: '董事/工程师', profiles: '自动化智能设备专家， 冻眠设备国产化工作实施者。' },
        { id: '6', pic: require('@/assets/about/team6.png'), fullname: '姚华', posts: '董事/副总经理', profiles: '深耕企业风控领域十七余年，于中国百强地产上市企业服务八年。' },
        { id: '7', pic: require('@/assets/about/team7.png'), fullname: '陈栋', posts: '股东/品牌形象大使', profiles: '清华大学、美国北佛罗里达大学MBA学位、粤港澳大湾区音乐艺术联盟副主席兼秘书长。' },
        { id: '8', pic: require('@/assets/about/team8.png'), fullname: '朱朝林', posts: '董事/高级经济师', profiles: '泰克尼康（重庆）科技公司董事长，冻眠设备西部产业基地。' },
        { id: '9', pic: require('@/assets/about/team9.png'), fullname: '杨必中', posts: '董事/企业管理专家', profiles: '泰克尼康（重庆）科技公司总裁。' },
      ],
      team_one: {}
    }
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Nav,
  },
  methods: {
    showDialog (obj) {
      this.dialogVisible = true
      this.team_one = obj
    },
    handleClose () {
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.about {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: 2000px;
  .banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    .banner-bg {
      width: 1920px;
      height: 750px;
      background: url("../assets/about.png") no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  }
  .aboat {
    position: relative;
    display: flex;
    width: 1400px;
    margin: 60px auto;
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 663px;
      h1 {
        font-size: 48px;
        font-weight: bold;
        line-height: 64px;
        color: #00a0e9;
        span {
          font-weight: 400;
          color: #66c6f2;
        }
      }
      h2 {
        font-size: 36px;
        font-weight: bold;
        line-height: 47px;
        color: #0a0c0d;
      }
      .text {
        font-size: 22px;
        line-height: 38px;
        color: #0a0c0d;
        margin-top: 50px;
        text-align: left;
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: flex-end;
      margin-left: 40px;
      overflow: hidden;
      img {
        width: 680px;
        height: 460px;
        transition: all 1.5s;
      }
      img:hover {
        transform: scale(1.25);
      }
    }
  }
  .trait {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 1600px;
    background-color: #f7f7f7;
    padding: 100px 0;
    .items {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 330px;
      height: 380px;
      background-color: #fff;
      border-radius: 10px;
      margin-right: 20px;
      padding: 35px 40px;
      text-align: center;
      img {
        margin-bottom: 60px;
      }
      h3 {
        font-size: 26px;
        font-weight: bold;
        color: #f3ce13;
        line-height: 35px;
        margin-bottom: 25px;
      }
      span {
        font-size: 22px;
        color: #000;
        line-height: 35px;
        max-width: 250px;
      }
    }
  }
  .skills {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 1600px;
    height: 515px;
    background: url("../assets/about/bg2.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    & .explain {
      width: 1400px;
      padding: 100px 0;
      & h3 {
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        line-height: 48px;
        margin-bottom: 55px;
        text-align: center;
      }
      & .desc {
        font-size: 26px;
        line-height: 40px;
        color: #fff;
        & p {
          text-indent: 50px;
          letter-spacing: 3px;
        }
      }
    }
  }
  .process {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 1400px;
    margin: 100px auto;
    .yellow,
    .blue,
    .white {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .yellow {
      position: absolute;
      width: 49px;
      height: 49px;
      background-color: #f3ce13;
      z-index: 1;
      .blue {
        width: 37px;
        height: 37px;
        background-color: #00a0e9;
        .white {
          width: 23px;
          height: 23px;
          background-color: #fff;
        }
      }
    }
    .left,
    .right {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 680px;
      height: 580px;
    }
    .text1,
    .text2 {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 680px;
      height: 280px;
      border-radius: 10px;
      padding: 25px 40px;
      h3 {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      p {
        max-width: 610px;
        font-size: 22px;
        line-height: 35px;
      }
    }
    .text1 {
      background-color: #fff;
      border: 1px solid #b5b6b6;
      h3 {
        color: #00a0e9;
      }
    }
    .text2 {
      background-color: #00a0e9;
      h3 {
        color: #f3ce13;
      }
      p {
        color: #fff;
      }
    }
  }
  .teams {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 1600px;
    background-color: #f7f7f7;
    .title {
      margin: 75px 0;
      .en-title {
        font-size: 48px;
        font-weight: bold;
        color: #e6e6e6;
        letter-spacing: 10px;
        text-align: center;
      }
      .chs-title {
        font-size: 36px;
        font-weight: bold;
        color: #000;
        letter-spacing: 5px;
        text-align: center;
        margin-top: -30px;
      }
    }
    .team-lists {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      width: 1400px;
      .team-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        margin: 20px 0 50px;
        cursor: pointer;
        .pic {
          width: 150px;
          height: 150px;
          margin-bottom: 20px;
          border-radius: 50%;
          overflow: hidden;
        }
        .info {
          flex: 1;
          .fullname,
          .posts {
            font-size: 26px;
            font-weight: bold;
            line-height: 40px;
            text-align: center;
            margin-bottom: 10px;
          }
          .fullname {
            color: #00a0e9;
          }
          .posts {
            color: #000;
          }
        }
        .profiles {
          margin-top: 15px;
          p {
            font-size: 22px;
            color: #3b3d3d;
            line-height: 35px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .food-base {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1400px;
    height: 630px;
    margin: 100px auto;
    .left {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 622px;
      height: 630px;
      color: #fff;
      background: url("../assets/about/bg3.png") no-repeat;
      background-size: 100% 100%;
      padding: 80px 70px;
      margin-right: 10px;
      h3 {
        font-size: 36px;
        font-weight: bold;
        line-height: 50px;
        margin-bottom: 60px;
      }
      p {
        font-size: 28px;
        font-weight: lighter;
        line-height: 40px;
        text-indent: 50px;
      }
    }
    .right {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 748px;
      height: 630px;
      .top {
        padding: 65px 38px;
        h3 {
          font-size: 36px;
          font-weight: bold;
          line-height: 50px;
          margin-bottom: 35px;
        }
        p {
          font-size: 22px;
          line-height: 33px;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        height: 277px;
        .bg {
          width: 369px;
          height: 277px;
          overflow: hidden;
        }
        .bg img {
          transition: all 1.5s;
        }
        .bg img:hover {
          transform: scale(1.25);
        }
      }
    }
  }
  .messages {
    /deep/.el-dialog {
      border-radius: 20px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    /deep/.el-dialog__header {
      padding: 20px 30px;
      border-bottom: 1px solid #e5e7e6;
      text-align: center;
      .el-dialog__title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    /deep/.el-dialog__headerbtn {
      right: 25px;
      font-size: 22px;
    }
    /deep/.el-dialog__body {
      display: flex;
      justify-content: center;
      width: 800px;
      padding: 20px 30px;
    }
    .team-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;
      margin: 20px 0 50px;
      cursor: pointer;
      .pic {
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
        border-radius: 50%;
        overflow: hidden;
      }
      .info {
        flex: 1;
        .fullname,
        .posts {
          font-size: 26px;
          font-weight: bold;
          line-height: 40px;
          text-align: center;
          margin-bottom: 10px;
        }
        .fullname {
          color: #00a0e9;
        }
        .posts {
          color: #000;
        }
      }
      .profiles {
        margin-top: 15px;
        p {
          font-size: 22px;
          color: #3b3d3d;
          line-height: 35px;
        }
      }
    }
  }
}
</style>